






































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { FengeEye } from "@/request/research";
@Component({})
export default class FengeDialog extends Vue {
  @Prop()
  private picSrc: any;
  @Prop()
  private visible: any;
  @Prop()
  private data: any;
  private state: string = "cutting";
  private canvasWrap: any = {};
  private sourceImage: any = {
    imgObj: {},
    x: 0,
    y: 0,
    sourceX: 0,
    sourceY: 0,
    centerX: 0,
    centerY: 0,
    width: 0,
    height: 0,
    originWidth: 0,
    originHeight: 0,
    rotateAngel: 0,
    moveX: 0,
    moveY: 0,
    scaleX: 1,
    scaleY: 1,
    aspectRadio: 1,
  };
  private canvas: any = {};
  private context: any = {};
  // 操作值
  private startPoint: any = {};
  // 临时路径
  private tempPath: any[] = [];
  private get ifVisible() {
    return this.visible;
  }
  private set ifVisible(val) {
    this.$emit("update:visible", val);
  }
  private get canvasClientX() {
    return (
      (document.body.clientWidth -
        document.getElementsByClassName("fengeDialog")[0].clientWidth) /
      2
    );
  }
  private get canvasClientY() {
    return (
      (document.body.clientHeight -
        document.getElementsByClassName("fengeDialog")[0].clientHeight) /
      2
    );
  }
  @Watch("picSrc")
  private picSrcChange(src: any) {
    this.initCanvas();
  }
  /**
   * @description 初始化canvas和显示图片
   */
  private initCanvas() {
    this.$nextTick().then(() => {
      this.canvasWrap = this.$refs.canvasWrap;
      this.canvas = this.$refs.canvas;
      this.canvas.width = this.canvasWrap.offsetWidth;
      this.canvas.height = this.canvasWrap.offsetHeight;
      this.context = this.canvas.getContext("2d");
      // 绘制黑色背景
      this.context.fillStyle = "black";
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawImgInInitCanvas();
    });
  }
  /**
   * @description 初始化绘制图片到canvas里
   */
  private drawImgInInitCanvas() {
    const imageObj = new Image();
    imageObj.crossOrigin = "anonymous";
    imageObj.onload = (e: any) => {
      this.sourceImage.imgObj = imageObj;
      let dx: number = 0;
      let dy: number = 0;
      let dWidth: any = 0;
      let dHeight: any = 0;
      // 处理缩放
      this.sourceImage.originWidth = imageObj.width;
      this.sourceImage.originHeight = imageObj.height;
      const originAspectRatio: any = imageObj.width / imageObj.height;
      this.sourceImage.aspectRadio = originAspectRatio;
      if (imageObj.width > this.canvas.width) {
        dWidth = this.canvas.width;
        dHeight = Math.round(dWidth / originAspectRatio);
        if (dHeight > this.canvas.height) {
          dHeight = this.canvas.height;
          dWidth = Math.round(dHeight * originAspectRatio);
        }
      } else {
        dHeight = this.canvas.height;
        dWidth = Math.round(dHeight * originAspectRatio);
        if (dWidth > this.canvas.width) {
          dWidth = this.canvas.width;
          dHeight = Math.round(dWidth / originAspectRatio);
        }
      }
      // 记录底图宽高
      this.sourceImage.width = dWidth;
      this.sourceImage.height = dHeight;
      // 处理居中
      dx = (this.canvas.width - dWidth) / 2;
      dy = (this.canvas.height - dHeight) / 2;
      // 记录底图左上角原点位置
      this.sourceImage.sourceX = this.sourceImage.x = dx;
      this.sourceImage.sourceY = this.sourceImage.y = dy;
      // 记录底图中心点
      this.sourceImage.centerX = dx + dWidth / 2;
      this.sourceImage.centerY = dy + dHeight / 2;
      // 绘图
      this.context.drawImage(
        imageObj,
        this.sourceImage.x,
        this.sourceImage.y,
        dWidth,
        dHeight
      );
    };
    imageObj.src = this.picSrc.url + "?t=" + new Date().getTime();
  }
  /**
   * @description 鼠标按下
   */
  private handleMouseDown(e: any) {
    if (this.state !== "cutting") {
      return;
    }
    this.startPoint = { offsetX: e.offsetX, offsetY: e.offsetY };
    this.context.save();
    this.context.beginPath();
    this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
  }
  /**
   * @description 鼠标移动
   */
  private handleMouseMove(e: any) {
    if (this.state !== "cutting") {
      return;
    }
    if (!this.startPoint.offsetX) {
      return;
    }
    this.drawBlackBack().then(() => {
      this.draw();
      this.context.lineTo(e.offsetX, e.offsetY);
      this.context.stroke();
    });
  }
  /**
   * @description 鼠标弹起
   */
  private handleMouseUp() {
    if (this.state !== "cutting") {
      return;
    }
    this.context.closePath();
    this.context.stroke();
    this.context.save();
    this.startPoint = {};
  }
  /**
   * @description 手指按下
   */
  private handleTouchStart(e: any) {
    if (this.state !== "cutting") {
      return;
    }
    this.startPoint = {
      offsetX: e.touches[0].clientX - this.canvasClientX,
      offsetY: e.touches[0].clientY - this.canvasClientY,
    };
    this.context.save();
    this.context.beginPath();
    this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
  }
  /**
   * @description 手指移动
   */
  private handleTouchMove(e: any) {
    if (this.state !== "cutting") {
      return;
    }
    if (!this.startPoint.offsetX) {
      return;
    }
    this.drawBlackBack().then(() => {
      this.draw();
      this.context.lineTo(
        e.touches[0].clientX - this.canvasClientX,
        e.touches[0].clientY - this.canvasClientY
      );
      this.context.stroke();
    });
  }
  /**
   * @description 手指弹起
   */
  private handleTouchEnd(e: any) {
    if (this.state !== "cutting") {
      return;
    }
    this.context.closePath();
    this.context.stroke();
    this.context.save();
    this.startPoint = {};
  }

  /**
   * @description 后续绘制
   */
  private draw() {
    this.context.drawImage(
      this.sourceImage.imgObj,
      this.sourceImage.x,
      this.sourceImage.y,
      this.sourceImage.width,
      this.sourceImage.height
    );
  }
  /**
   * @description 绘制黑色背景
   */
  private drawBlackBack() {
    this.context.save();
    return new Promise((resolve) => {
      this.context.fillRect(
        -this.canvas.width * 2,
        -this.canvas.height * 2,
        this.canvas.width * 5,
        this.canvas.height * 5
      );
      this.context.restore();
      resolve(true);
    });
  }
  /**
   * @description 还原
   */
  private restore() {
    this.state = "cutting";
    this.startPoint = {};
    this.initCanvas();
  }
  /**
   * @description 分割
   */
  private cut() {
    this.drawBlackBack().then(async () => {
      this.context.clip();
      this.draw();
      this.startPoint = {};
      this.context.restore();
      this.state = "cutted";
    });
  }
  /**
   * @description 确定
   */
  private confirm() {
    const imgData = (this.canvas as HTMLCanvasElement).toDataURL(
      "image/jpeg",
      1.0
    );
    const params: any = {
      eye_id: this.data.eye_id,
      base64_image: imgData,
      type: this.picSrc.type,
    };
    FengeEye(this, params).then((data: any) => {
      this.$message.success("手动分割保存成功");
      this.$emit("confirm");
      this.ifVisible = false;
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.initCanvas();
    window.onresize = () => {
      this.initCanvas();
    };
  }
}
