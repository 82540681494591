

































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetLiulidu } from "@/request/research";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Watch("type", { immediate: true })
  private typeChange() {
    this.detail =
      this.data[this.type == "left" ? "左手脉象信息输出" : "右手脉象信息输出"];
    this.dingliangData =
      this.data[
        this.type == "left" ? "左手最佳脉象图参数" : "右手最佳脉象图参数"
      ];
  }
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.detail =
      this.data[this.type == "left" ? "左手脉象信息输出" : "右手脉象信息输出"];
    this.dingliangData =
      this.data[
        this.type == "left" ? "左手最佳脉象图参数" : "右手最佳脉象图参数"
      ];
  }
  private detail: any = {};
  private changeName(e: any) {
    const params: any = {
      脉名: e,
    };
    GetLiulidu(this, params).then((res: any) => {
      this.detail["流利度"] = res["流利度"];
      this.detail["紧张度"] = res["紧张度"];
    });
  }
  private dingliangData: any = {};
  private dingliang: any = [
    {
      name: "t1",
      label: "t1(s)",
      type: "单选",
    },
    {
      name: "t2",
      label: "t2(s)",
      type: "单选",
    },
    {
      name: "t3",
      label: "t3(s)",
      type: "单选",
    },
    {
      name: "t4",
      label: "t4(s)",
      type: "单选",
    },
    {
      name: "t5",
      label: "t5(s)",
      type: "单选",
    },
    {
      name: "h3/h1",
      label: "h3/h1",
      type: "单选",
    },
    {
      name: "h5/h1",
      label: "h5/h1",
      type: "单选",
    },
    {
      name: "w1/t",
      label: "w1/t",
      type: "单选",
    },
    {
      name: "w1",
      label: "w1",
      type: "单选",
    },
    {
      name: "h1",
      label: "h1(g)",
      type: "单选",
    },
    {
      name: "h2",
      label: "h2(g)",
      type: "单选",
    },
    {
      name: "h3",
      label: "h3(g)",
      type: "单选",
    },
    {
      name: "h4",
      label: "h4(g)",
      type: "单选",
    },
    {
      name: "h5",
      label: "h5(g)",
      type: "单选",
    },
    {
      name: "h4/h1",
      label: "h4/h1",
      type: "单选",
    },

    {
      name: "t1/t",
      label: "t1/t",
      type: "单选",
    },
    {
      name: "w2/t",
      label: "w2/t",
      type: "单选",
    },
    {
      name: "w2",
      label: "w2",
      type: "单选",
    },
    {
      name: "As",
      label: "As(g*s)",
      type: "单选",
    },
    {
      name: "Ad",
      label: "Ad(g*s)",
      type: "单选",
    },
    {
      name: "A",
      label: "A(g*s)",
      type: "单选",
    },
    {
      name: "t",
      label: "t(s)",
      type: "单选",
    },
    {
      name: "t5/t4",
      label: "t5/t4",
      type: "单选",
    },
  ];
  private maiName: any = [];
  private options: any = {
    脉位: ["沉", "中", "浮"],
    脉节律: ["齐", "不齐"],
    脉力: ["无力", "中", "有力"],
    紧张度: ["弦", "紧", "无弦、紧特征"],
    流利度: ["滑", "涩", "无滑、涩特征"],
    脉率: ["中", "慢", "快", "缓", "数", "迟", "疾"],
    脉名: [
      "脉虚弦促沉",
      "脉促而虚",
      "脉虚弦沉",
      "脉滑",
      "脉虚而代弦",
      "脉结弦",
      "脉虚浮促",
      "脉数",
      "脉虚",
      "脉弦",
      "脉结虚",
      "脉虚缓",
      "脉缓",
      "脉结",
      "脉虚弦结",
      "脉沉虚",
      "脉虚弦",
      "脉虚而代",
      "脉浮而滑结",
      "脉虚浮结",
      "脉沉而结虚",
      "脉迟而弦",
      "脉缓弦",
      "脉虚缓弦",
      "脉促",
      "脉浮数虚弦",
      "脉代而弦",
      "脉沉而数",
      "脉浮而滑结虚",
      "脉虚浮弦",
      "其它脉",
      "脉虚缓沉弦",
      "脉浮数虚",
      "脉虚浮结弦",
      "脉弦而数",
      "脉沉而迟弦",
      "脉虚弦促",
      "脉促而滑",
      "脉结滑",
      "脉沉弦",
      "脉迟",
      "脉浮",
      "脉沉弦结",
      "脉促而弦",
      "脉浮弦",
      "脉浮缓虚弦",
      "脉代",
      "脉沉缓弦",
      "脉沉而结",
      "脉浮而迟弦",
      "脉虚数",
      "脉虚弦结沉",
      "脉虚滑结",
      "脉虚弦数",
      "脉虚弦迟",
      "脉沉而数弦",
      "脉虚浮",
      "脉虚数沉",
      "脉沉滑",
      "脉缓滑",
      "脉平",
      "脉虚滑",
      "脉滑数",
      "脉沉缓",
      "脉虚浮代",
      "脉浮缓弦",
      "脉促沉",
      "脉沉弦促",
      "脉浮而结弦",
      "脉虚缓沉",
      "脉虚而代沉",
      "脉弦滑",
      "脉虚而代沉弦",
      "脉虚浮促弦",
      "脉促而虚沉",
      "脉浮而代弦",
      "脉沉",
      "脉浮而结",
      "脉虚滑促",
      "脉浮缓",
      "脉弦滑促",
      "脉虚弦数沉",
      "脉浮而滑促虚",
      "脉沉滑促",
      "脉沉滑结",
      "脉虚浮代弦",
      "脉虚浮疾",
      "脉弦滑结",
      "脉浮而滑促",
      "脉迟虚",
      "脉浮而滑",
      "脉虚滑促沉",
      "脉沉弦代",
      "脉沉而数滑弦",
      "脉浮而滑代",
      "脉浮而迟虚",
      "脉实而弦结",
      "脉沉而数滑",
      "脉沉滑弦",
      "脉虚滑结沉",
      "脉虚弦迟沉",
      "脉浮缓滑",
      "脉虚而代滑",
      "脉浮数弦",
      "脉虚缓滑",
      "脉迟滑",
      "脉沉而迟虚",
      "脉沉弦疾",
      "脉弦而数滑",
      "脉浮而迟虚弦",
      "脉虚数滑",
      "脉弦滑缓",
      "脉虚弦结滑",
      "脉疾而滑",
      "脉浮而滑虚",
      "脉代而滑",
      "脉沉而迟",
      "脉实",
      "脉浮数",
      "脉浮而滑促虚弦",
      "脉虚弦促滑",
      "脉沉滑结弦",
      "脉促浮",
      "脉疾而虚",
      "脉沉而代",
      "脉疾浮",
      "脉沉滑缓",
      "脉浮缓虚",
      "脉虚而代沉滑",
      "脉沉滑促弦",
      "脉浮数滑",
      "脉虚弦疾",
      "脉促而弦浮",
      "脉沉滑缓弦",
      "脉实而数滑",
      "脉浮而迟",
      "脉虚弦滑",
      "脉虚滑沉",
      "脉沉而迟滑弦",
      "脉疾",
      "脉浮数虚滑",
      "脉代而弦滑",
      "脉实而弦缓",
      "脉浮而代",
      "脉迟而弦滑",
      "脉疾而弦",
      "脉沉滑代",
      "脉虚数沉滑",
      "脉实而弦",
      "脉虚滑疾沉",
      "脉沉而迟滑",
      "脉实而滑缓",
      "脉结实",
      "脉实而滑结",
      "脉虚缓沉滑",
      "脉虚弦疾沉",
      "脉实而弦迟",
      "脉浮而滑代虚",
      "脉虚弦促沉滑",
      "脉浮而滑结虚弦",
      "脉虚弦迟沉滑",
      "脉疾而虚沉",
      "脉虚而代滑弦",
      "脉沉滑疾",
      "脉浮而滑迟",
      "脉虚弦结沉滑",
      "脉缓实",
    ],
  };
  private formatTooltip(val: any) {
    return val + "度";
  }
  private valueChange() {
    const d = JSON.parse(JSON.stringify(this.data));
    d[this.type == "left" ? "left_eye" : "right_eye"]["黑睛"] = this.detail;
    this.$emit("change", d);
  }
}
