











































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  GetPulseTags,
  EditPulseTags,
  GetMaiDetail2,
  GetMaiDetailZhenci,
  EditPulseDetail,
} from "@/request/research";
import PicCanvas from "./components/pic-canvas.vue";
import Fenge from "./components/fenge.vue";
import Jianyi from "./components/jianyi.vue";
import Pulse from "./components/pulse.vue";
@Component({
  components: {
    PicCanvas,
    Fenge,
    Jianyi,
    Pulse,
  },
})
export default class IdrBiaozhu extends Vue {
  private projectId: any = "";
  private id: any = "";
  private options: any = {};
  private currentPicSrc: any = {};
  private pics: any = {
    left: [],
    right: [],
  };
  private detail: any = {
    专家建议: {},
    轨迹: {},
  };
  private data: any[] = [];
  private model: any = "";
  private picType: any = "origin";
  private cPart: any = "脉象";
  private ifShowBidui: boolean = false;
  private loaded: boolean = false;
  private ifOpenFenge: boolean = false;
  private kuankuanbiHelp: boolean = false;
  private labels: any = [];
  private ifShowAddLabel: boolean = false;
  private tempLabels: any[] = [];
  private source_type: any = "";
  private get picCanvas() {
    return this.$refs.picCanvas as any;
  }
  @Watch("$route.query", { immediate: true, deep: true })
  private queryChange() {
    this.id = this.$route.query.id;
    this.projectId = this.$route.query.project_id;
    this.source_type = this.$route.query.source_type;
    if (this.$route.query.pType) {
      this.cPart = this.$route.query.pType;
    }
    this.fetchDetail().then(() => {
      this.fetchPatientAllTongue();
    });
  }
  private update(e: any) {
    this.detail = e;
    this.$forceUpdate();
  }
  /**
   * @description 跳转智能比对
   */
  private jumpBidui() {
    // localStorage.setItem("selectedTongues", JSON.stringify([this.detail]));
    // this.$router.push("/main/research/tongue/bidui-list");
  }
  /**
   * @description 保存
   */
  private save() {
    const params: any = JSON.parse(JSON.stringify(this.detail));
    EditPulseDetail(this, params).then((data: any) => {
      this.$message.success("标注成功");
      this.fetchDetail();
    });
  }
  /**
   * @description 获得该患者的全部图片
   */
  private fetchPatientAllTongue() {
    const params: any = {
      params: {
        patient_id: this.detail.patient_id,
        source_id: this.detail.source_id,
        source_type: this.detail.source_type,
        // project_id: this.projectId,
      },
    };
    GetMaiDetailZhenci(this, params).then((data: any) => {
      this.data = data;
    });
  }
  /**
   * @description 获得详情
   */
  private fetchDetail() {
    this.loaded = false;
    return new Promise((resolve, reject) => {
      // const params: any = {
      //   type: "脉图数据采集报告",
      //   id: "7047c201a965463a833e3662614a0bfc",
      // };
      const params: any = {
        params: {
          pulse_id: this.id,
          source_type: this.source_type,
        },
      };
      GetMaiDetail2(this, params)
        .then((data: any) => {
          if (!data["轨迹"]) {
            data["轨迹"] = {};
          }
          this.detail = data;
          this.pics = {
            left: [],
            right: [],
          };
          // 处理图片展示
          // 处理左脉的
          if (this.detail["左手分段压脉图"]) {
            const obj = {
              type: "left",
              index: this.pics.left.length,
              name: "左手分段压脉图",
              url: this.detail["左手分段压脉图"],
            };
            this.pics.left.push(obj);
          }
          if (this.detail["左手连续脉图"]) {
            const obj = {
              type: "left",
              index: this.pics.left.length,
              name: "左手连续脉图",
              url: this.detail["左手连续脉图"],
            };
            this.pics.left.push(obj);
          }
          if (this.detail["左手脉象p-h趋势图"]) {
            const obj = {
              type: "left",
              index: this.pics.left.length,
              name: "左手脉象p-h趋势图",
              url: this.detail["左手脉象p-h趋势图"],
            };
            this.pics.left.push(obj);
          }
          if (this.detail["左手最佳压力脉图"]) {
            const obj = {
              type: "left",
              index: this.pics.left.length,
              name: "左手最佳压力脉图",
              url: this.detail["左手最佳压力脉图"],
            };
            this.pics.left.push(obj);
          }
          // 处理右脉的
          if (this.detail["右手分段压脉图"]) {
            const obj = {
              type: "right",
              index: this.pics.right.length,
              name: "右手分段压脉图",
              url: this.detail["右手分段压脉图"],
            };
            this.pics.right.push(obj);
          }
          if (this.detail["右手连续脉图"]) {
            const obj = {
              type: "right",
              index: this.pics.right.length,
              name: "右手连续脉图",
              url: this.detail["右手连续脉图"],
            };
            this.pics.right.push(obj);
          }
          if (this.detail["由手脉象p-h趋势图"]) {
            const obj = {
              type: "right",
              index: this.pics.right.length,
              name: "右手脉象p-h趋势图",
              url: this.detail["右手脉象p-h趋势图"],
            };
            this.pics.right.push(obj);
          }
          if (this.detail["右手最佳压力脉图"]) {
            const obj = {
              type: "right",
              index: this.pics.right.length,
              name: "右手最佳压力脉图",
              url: this.detail["右手最佳压力脉图"],
            };
            this.pics.right.push(obj);
          }
          if (this.pics.left.length > 0) {
            this.currentPicSrc = this.pics.left[0];
          } else {
            this.currentPicSrc = this.pics.right[0];
          }
          this.loaded = true;
          // 模拟数据
          // this.detail.patient_id = "fa5aaf94472f";
          // if (!this.detail["左手定性参数"]) {
          //   this.detail["左手定性参数"] = {
          //     紧张度: [],
          //     流利度: [],
          //   };
          // }
          // if (!this.detail["右手定性参数"]) {
          //   this.detail["右手定性参数"] = {
          //     紧张度: [],
          //     流利度: [],
          //   };
          // }
          // if (!this.detail["专家建议"]) {
          //   this.detail["专家建议"] = {
          //     专家建议: "",
          //     按诊断分: {
          //       中医疾病: [],
          //       中医证型: [],
          //       疾病分型: "",
          //       病程: "",
          //       西医疾病: [],
          //     },
          //   };
          // }
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 返回列表
   */
  private backList() {
    this.$router.push("/main/research/pulse/list");
  }
  /**
   * @description 跳转图预览
   */
  private jumpPreview() {
    const params: any = JSON.parse(JSON.stringify(this.detail));
    EditPulseDetail(this, params).then((data: any) => {
      this.$router.push({
        path: "/main/research/pulse/preview",
        query: {
          id: this.detail.pulse_id,
          source_type: this.detail.source_type,
        },
      });
    });
  }
  /**
   * @description 切换同一个患者的其他图
   */
  private changeZhenci(d: any) {
    this.$router.push({
      path: "/main/research/pulse/biaozhu",
      query: {
        id: d.pulse_id,
        project_id: this.projectId,
      },
    });
  }
  /**
   * @description 切换展示的图片
   */
  private changeCurrentPic(src: any, model: any) {
    this.currentPicSrc = src;
    this.picType = model;
  }
  // 图像标注
  /**
   * @description 局部舌色
   */
  private jubushese() {
    this.model = "局部舌色";
    this.cPart = "望舌体";
    this.$nextTick().then(() => {
      (this.$refs.sheti as any).jubusheseScrollToView();
    });
    this.picCanvas.jubushese();
  }
  /**
   * @description 删除局部舌色
   */
  private deleteJubushese() {
    this.picCanvas.deleteJubushese();
  }
  /**
   * @description 局部苔色
   */
  private jubutaise() {
    this.model = "局部苔色";
    this.cPart = "望舌苔";
    this.$nextTick().then(() => {
      (this.$refs.shetai as any).jubutaiseScrollToView();
    });
    this.picCanvas.jubutaise();
  }
  /**
   * @description 删除局部苔色
   */
  private deleteJubutaise() {
    this.picCanvas.deleteJubutaise();
  }

  /**
   * @description 舌上红点
   */
  private sheshanghongdian() {
    this.model = "舌上红点";
    this.cPart = "望舌体";
    // this.$nextTick().then(() => {
    //   (this.$refs.sheti as any).tezhengbiaozhuScrollToView();
    // });
    this.picCanvas.sheshanghongdian();
  }
  /**
   * @description 删除舌上红点
   */
  private deleteSheshanghongdian() {
    this.picCanvas.deleteSheshanghongdian();
  }
  /**
   * @description 瘀斑
   */
  private yuban() {
    this.model = "瘀斑";
    this.picCanvas.yuban();
  }
  /**
   * @description 删除某个瘀斑
   */
  private deleteYuban(index: any) {
    this.picCanvas.deleteYuban(index);
  }
  /**
   * @description 齿痕
   */
  private chihen() {
    this.model = "齿痕";
    this.picCanvas.chihen();
  }
  /**
   * @description 删除某个齿痕
   */
  private deleteChihen(index: any) {
    this.picCanvas.deleteChihen(index);
  }
  /**
   * @description 裂纹
   */
  private liewen() {
    this.model = "裂纹";
    this.picCanvas.liewen();
  }
  /**
   * @description 删除某个裂纹
   */
  private deleteLiewen(index: any) {
    this.picCanvas.deleteLiewen(index);
  }
  /**
   * @description 胖瘦
   */
  private pangshou() {
    this.model = "胖瘦";
    // this.cPart = "望舌体";
    if (!this.detail["轨迹"]["胖瘦"]) {
      this.detail["轨迹"]["胖瘦"] = {
        嘴角间距: {},
        舌间距: {},
      };
    }
    // this.$nextTick().then(() => {
    //   (this.$refs.sheti as any).tezhengbiaozhuScrollToView();
    // });
    this.picCanvas.pangshou();
    if (this.kuankuanbiHelp === false && this.picType === "origin") {
      this.$confirm(
        "宽宽比是指嘴角间距和舌两边距离的比值，您可以先在嘴角画一条线，再在舌两边画一条线，系统就会自动得出宽宽比",
        {
          showCancelButton: false,
        }
      );
    }
    this.kuankuanbiHelp = true;
  }
  /**
   * @description 删除胖瘦的某条线
   */
  private deletePangshou(part: any) {
    this.picCanvas.deletePangshou(part);
  }
  /**
   * @description 苔质
   */
  private taizhi() {
    this.model = "苔质";
    this.picCanvas.taizhi();
  }
  /**
   * @description 删除某个苔质
   */
  private deleteTaizhi(index: any) {
    this.picCanvas.deleteTaizhi(index);
  }
  /**
   * @description 剥落
   */
  private boluo() {
    this.model = "剥落";
    this.picCanvas.boluo();
  }
  /**
   * @description 删除某个剥落
   */
  private deleteBoluo(index: any) {
    this.picCanvas.deleteBoluo(index);
  }
  /**
   * @description 局部舌苔
   */
  private jubushetai() {
    this.model = "舌苔局部放大图";
    this.picCanvas.jubushetai();
  }
  /**
   * @description 删除某个舌苔局部放大图
   */
  private deleteJubushetai(index: any) {
    this.picCanvas.deleteJubushetai(index);
  }
  /**
   * @description 点刺
   */
  private dianci() {
    this.model = "点刺";
    this.picCanvas.dianci();
  }
  /**
   * @description 删除某个点刺
   */
  private deleteDianci(index: any) {
    this.picCanvas.deleteDianci(index);
  }
  /**
   * @description 舌态
   */
  private shetai() {
    this.model = "舌态";
    this.picCanvas.shetai();
  }
  /**
   * @description 删除某个舌态
   */
  private deleteShetai(index: any) {
    this.picCanvas.deleteShetai(index);
  }
  /**
   * @description 瘀点
   */
  private yudian() {
    this.model = "瘀点";
    this.picCanvas.yudian();
  }
  /**
   * @description 删除某个瘀点
   */
  private deleteYudian(index: any) {
    this.picCanvas.deleteYudian(index);
  }
  /**
   * @description 打开分割弹窗
   */
  private fenge() {
    this.ifOpenFenge = true;
  }
  // 图像操作
  /**
   * @description 移动
   */
  private yidong() {
    this.model = "移动";
    this.picCanvas.yidong();
  }
  /**
   * @description 缩放
   */
  private suofang() {
    this.model = "缩放";
    this.picCanvas.suofang();
  }
  /**
   * @description 放大镜
   */
  private fangdajing() {
    this.model = "放大镜";
    this.picCanvas.fangdajing();
    this.cPart = "望舌苔";
    this.$nextTick().then(() => {
      (this.$refs.shetai as any).jubushetaiScrollToView();
    });
  }
  /**
   * @description 旋转
   */
  private xuanzhuan() {
    this.model = "旋转";
    this.picCanvas.xuanzhuan();
  }
  /**
   * @description 截屏
   */
  private jieping() {
    this.model = "截屏";
    this.picCanvas.jieping();
  }
  /**
   * @description 还原
   */
  private huanyuan() {
    this.model = "还原";
    this.picCanvas.huanyuan();
  }
  /**
   * @description 改变model
   */
  private modelChange(model: any) {
    this.model = model;
  }
  /**
   * @description 切换显示的部分
   */
  private changePart(part: any) {
    this.cPart = part;
  }
  /**
   * @description 获得可选的标签列表
   */
  private fetchBiaoqianList() {
    return new Promise((resolve, reject) => {
      GetPulseTags(this)
        .then((data: any) => {
          this.labels = data;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * 当前标签是否被选中
   */
  private getHas(name: any) {
    let result = false;
    if (this.detail["labels"] && this.detail["labels"].length > 0) {
      this.detail["labels"].forEach((ele: any) => {
        if (ele["tag_name"] === name) {
          result = true;
        }
      });
    }
    return result;
  }
  /**
   * @description 删除当前舌图标签
   */
  private delOpt(i: any) {
    this.detail["labels"].splice(i, 1);
    this.$forceUpdate();
  }
  /**
   * @description 选择舌图标签
   */
  private selOne(item: any) {
    if (this.getHas(item["名称"])) {
      return;
    }
    if (!this.detail["labels"]) {
      this.detail["labels"] = [];
    }
    this.detail["labels"].push(item);
    this.$forceUpdate();
  }
  /**
   * @description 打开添加标签弹窗
   */
  private openAddLabel() {
    this.tempLabels = JSON.parse(JSON.stringify(this.labels));
    if (this.tempLabels.length === 0) {
      this.$set(this.tempLabels, "0", { tag_name: "" });
    }
    this.ifShowAddLabel = true;
  }
  /**
   * @description 关闭弹窗
   */
  private closeAddLabel() {
    this.ifShowAddLabel = false;
  }
  /**
   * @description 刷新页面
   */
  private refresh() {
    this.fetchBiaoqianList().then(() => {
      this.filterSelectedLables();
    });
  }
  /**
   * @description 根据新的标签列表，筛选已选标签
   */
  private filterSelectedLables() {
    this.detail["labels"].forEach((item: any, index: any) => {
      this.labels.forEach((i: any) => {
        if (i.tag_id === item.tag_id) {
          this.detail["labels"].splice(index, 1);
        }
      });
    });
  }
  /**
   * @description 添加临时标签
   */
  private addLabel() {
    this.tempLabels.unshift({ tag_name: "" });
  }
  /**
   * @description 删除一个标签
   */
  private deleteLabel(index: any) {
    if (this.tempLabels.length === 1) {
      this.tempLabels = [{ tag_name: "" }];
    } else {
      this.tempLabels.splice(index, 1);
    }
  }
  /**
   * @description 保存对标签的修改
   */
  private saveAddedLabel() {
    const params: any = {
      tags: this.tempLabels,
    };
    EditPulseTags(this, params).then(() => {
      this.$message.success("修改成功");
      this.ifShowAddLabel = false;
      this.tempLabels = [];
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.fetchBiaoqianList();
  }
}
