





























































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetTerms } from "@/components/template-transfer-pc/template-transfer/request";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.detail = this.data["专家建议"];
  }
  private options1: any = [];
  private options2: any = [];
  private options3: any = [];
  private options4: any = [
    "Ⅰ型",
    "Ⅱ型",
    "Ⅲ型",
    "Ⅳ型",
    "Ⅴ型",
    "Ⅵ型",
    "Ⅶ型",
    "Ⅷ型",
    "Ⅸ型",
    "Ⅹ型",
  ];
  private options5: any = [
    "第Ⅰ阶段",
    "第Ⅱ阶段",
    "第Ⅲ阶段",
    "第Ⅳ阶段",
    "第Ⅴ阶段",
    "第Ⅵ阶段",
    "第Ⅶ阶段",
    "第Ⅷ阶段",
    "第Ⅸ阶段",
    "第Ⅹ阶段",
  ];
  private detail: any = {
    按诊断分: {
      西医疾病: [],
      中医疾病: [],
      中医证型: [],
      疾病分型: "",
      病程: "",
    },
  };
  private visible: any = false;
  private pingfenTree: any = [
    {
      name: "脉络走向",
      children: [
        {
          name: "伸向瞳孔或离断",
          score: 3,
        },
        {
          name: "伸向其他",
          score: 2,
        },
        {
          name: "杂乱无章",
          score: 1,
        },
      ],
    },
    {
      name: "脉络大小",
      children: [
        {
          name: "根部粗大或较粗大",
          score: 3,
        },
        {
          name: "一般大",
          score: 2,
        },
        {
          name: "细小",
          score: 1,
        },
      ],
    },
    {
      name: "脉络颜色",
      children: [
        {
          name: "深红、绛红色",
          score: 3,
        },
        {
          name: "鲜红色",
          score: 2,
        },
        {
          name: "粉红色",
          score: 1,
        },
      ],
    },
    {
      name: "脉络弯曲度",
      children: [
        {
          name: "呈螺旋状或曲度大",
          score: 3,
        },
        {
          name: "蛇行状",
          score: 2,
        },
        {
          name: "较直或不规则",
          score: 1,
        },
      ],
    },
    {
      name: "脉络末端斑点",
      children: [
        {
          name: "斑块或大斑点",
          score: 3,
        },
        {
          name: "小斑点",
          score: 2,
        },
        {
          name: "无斑点",
          score: 1,
        },
      ],
    },
    {
      name: "黑睛的改变",
      children: [
        {
          name: "凹陷穹窿代谢环变化瞳孔异常",
          score: 3,
        },
        {
          name: "代谢环变化黑线或黑点",
          score: 2,
        },
        {
          name: "瞳孔异常",
          score: 1,
        },
      ],
    },
  ];
  private zhenduan: any = {};
  private openPingfen() {
    this.zhenduan = JSON.parse(JSON.stringify(this.detail["诊断评分"]));
    this.visible = true;
  }
  private sure() {
    if (!this.zhenduan["脉络走向"]) {
      this.$message.warning("脉络走向必选！");
      return;
    }
    if (!this.zhenduan["脉络大小"]) {
      this.$message.warning("脉络大小必选！");
      return;
    }
    if (!this.zhenduan["脉络颜色"]) {
      this.$message.warning("脉络颜色必选！");
      return;
    }
    if (!this.zhenduan["脉络弯曲度"]) {
      this.$message.warning("脉络弯曲度必选！");
      return;
    }
    if (!this.zhenduan["脉络末端斑点"]) {
      this.$message.warning("脉络末端斑点必选！");
      return;
    }
    if (!this.zhenduan["黑睛的改变"]) {
      this.$message.warning("黑睛的改变必选！");
      return;
    }
    // 算分
    let score: any = 0;
    this.pingfenTree.forEach((ele: any) => {
      ele.children.forEach((e: any) => {
        if (e.name == this.zhenduan[ele.name]) {
          score += e.score;
        }
      });
    });
    this.zhenduan.total_score = score.toString();
    this.detail["诊断评分"] = this.zhenduan;
    this.visible = false;
  }
  private valueChange() {
    const d = JSON.parse(JSON.stringify(this.data));
    d["专家建议"] = this.detail;
    this.$emit("change", d);
  }
  /**
   * @description 搜索
   */
  private fetchOptions(ev: any, key: any, optKey: any) {
    const params: any = {
      params: {
        type: key,
        search: ev,
      },
    };
    GetTerms(this, params).then((data: any) => {
      this.detail["按诊断分"][key].forEach((element: any) => {
        const index = data.findIndex((val: any) => {
          return element.id === val.id;
        });
        if (index === -1) {
          data.push(element);
        }
      });
      (this as any)[optKey] = data;
    });
  }
  private mounted() {
    this.fetchOptions("", "西医疾病", "options1");
    this.fetchOptions("", "中医疾病", "options2");
    this.fetchOptions("", "中医证型", "options3");
  }
}
